import React from 'react';

export const arrowRight = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2661 15.8857L20.2582 10.8835C20.746 10.3945 20.746 9.60541 20.2582 9.11645L15.2661 4.11426L13.4953 5.88128L16.3578 8.75004L0.624649 8.75003L0.624649 11.2511L16.3578 11.2511L13.4953 14.1186L15.2661 15.8857Z"
      fill="white"
    />
    <clipPath id="clip0_101:421">
      <rect width="20" height="20" fill="white" transform="translate(20.625) rotate(90)" />
    </clipPath>
  </svg>
);

export const step1 = (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25.0469" cy="25.75" r="25" fill="#01CA8E" />
    <path d="M22.1438 20.75V18.17H26.9638V32.75H24.0838V20.75H22.1438Z" fill="white" />
  </svg>
);

export const step2 = (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25.9219" cy="25.75" r="25" fill="#027AFF" />
    <path
      d="M22.1197 29.59C23.3997 28.5233 24.4197 27.6367 25.1797 26.93C25.9397 26.21 26.573 25.4633 27.0797 24.69C27.5864 23.9167 27.8397 23.1567 27.8397 22.41C27.8397 21.73 27.6797 21.1967 27.3597 20.81C27.0397 20.4233 26.5464 20.23 25.8797 20.23C25.213 20.23 24.6997 20.4567 24.3397 20.91C23.9797 21.35 23.793 21.9567 23.7797 22.73H21.0597C21.113 21.13 21.5864 19.9167 22.4797 19.09C23.3864 18.2633 24.533 17.85 25.9197 17.85C27.4397 17.85 28.6064 18.2567 29.4197 19.07C30.233 19.87 30.6397 20.93 30.6397 22.25C30.6397 23.29 30.3597 24.2833 29.7997 25.23C29.2397 26.1767 28.5997 27.0033 27.8797 27.71C27.1597 28.4033 26.2197 29.2433 25.0597 30.23H30.9597V32.55H21.0797V30.47L22.1197 29.59Z"
      fill="white"
    />
  </svg>
);

export const step3 = (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25.75" cy="25.75" r="25" fill="#FFC627" />
    <path
      d="M20.8637 22.01C20.9303 20.6767 21.397 19.65 22.2637 18.93C23.1437 18.1967 24.297 17.83 25.7237 17.83C26.697 17.83 27.5303 18.0033 28.2237 18.35C28.917 18.6833 29.437 19.1433 29.7837 19.73C30.1437 20.3033 30.3237 20.9567 30.3237 21.69C30.3237 22.53 30.1037 23.2433 29.6637 23.83C29.237 24.4033 28.7237 24.79 28.1237 24.99V25.07C28.897 25.31 29.497 25.7367 29.9237 26.35C30.3637 26.9633 30.5837 27.75 30.5837 28.71C30.5837 29.51 30.397 30.2233 30.0237 30.85C29.6637 31.4767 29.1237 31.97 28.4037 32.33C27.697 32.6767 26.8437 32.85 25.8437 32.85C24.337 32.85 23.1103 32.47 22.1637 31.71C21.217 30.95 20.717 29.83 20.6637 28.35H23.3837C23.4103 29.0033 23.6303 29.53 24.0437 29.93C24.4703 30.3167 25.0503 30.51 25.7837 30.51C26.4637 30.51 26.9837 30.3233 27.3437 29.95C27.717 29.5633 27.9037 29.07 27.9037 28.47C27.9037 27.67 27.6503 27.0967 27.1437 26.75C26.637 26.4033 25.8503 26.23 24.7837 26.23H24.2037V23.93H24.7837C26.677 23.93 27.6237 23.2967 27.6237 22.03C27.6237 21.4567 27.4503 21.01 27.1037 20.69C26.7703 20.37 26.2837 20.21 25.6437 20.21C25.017 20.21 24.5303 20.3833 24.1837 20.73C23.8503 21.0633 23.657 21.49 23.6037 22.01H20.8637Z"
      fill="white"
    />
  </svg>
);

export const copyIcon = (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8248 2.57582L16.7982 0.54918C16.4466 0.197548 15.9697 2.6003e-06 15.4724 0L8.74902 0C7.71348 0 6.87402 0.839453 6.87402 1.875V3.75H3.74902C2.71348 3.75 1.87402 4.58945 1.87402 5.625V18.125C1.87402 19.1605 2.71348 20 3.74902 20H12.499C13.5346 20 14.374 19.1605 14.374 18.125V16.25H17.499C18.5346 16.25 19.374 15.4105 19.374 14.375V3.90164C19.374 3.40436 19.1765 2.92745 18.8248 2.57582ZM12.2646 18.125H3.9834C3.92124 18.125 3.86162 18.1003 3.81767 18.0564C3.77372 18.0124 3.74902 17.9528 3.74902 17.8906V5.85938C3.74902 5.79721 3.77372 5.7376 3.81767 5.69365C3.86162 5.64969 3.92124 5.625 3.9834 5.625H6.87402V14.375C6.87402 15.4105 7.71348 16.25 8.74902 16.25H12.499V17.8906C12.499 17.9528 12.4743 18.0124 12.4304 18.0564C12.3864 18.1003 12.3268 18.125 12.2646 18.125ZM17.2646 14.375H8.9834C8.92124 14.375 8.86162 14.3503 8.81767 14.3064C8.77372 14.2624 8.74902 14.2028 8.74902 14.1406V2.10938C8.74902 2.04721 8.77372 1.9876 8.81767 1.94365C8.86162 1.89969 8.92124 1.875 8.9834 1.875H13.124V5.3125C13.124 5.83027 13.5437 6.25 14.0615 6.25H17.499V14.1406C17.499 14.2028 17.4743 14.2624 17.4304 14.3064C17.3864 14.3503 17.3268 14.375 17.2646 14.375V14.375ZM17.499 4.375H14.999V1.875H15.3753C15.4374 1.875 15.497 1.89969 15.541 1.94363L17.4304 3.83301C17.4522 3.85477 17.4694 3.88061 17.4812 3.90905C17.493 3.93749 17.499 3.96797 17.499 3.99875V4.375Z"
      fill="#898393"
    />
  </svg>
);

export const closeIcon = (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
      fill="#8C979D"
    />
  </svg>
);
