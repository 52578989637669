import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import moment from 'moment';
import Layout from '../components/layout';
import Steps from '../components/steps';
import Result from '../components/result';
import CTA from '../components/cta';
import Modal from '../components/modal';
import { arrowRight } from '../img/svg-icons';
import './styles.scss';

const isValidEmail = mail => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
};

export default props => {
  const rawPathnameValue =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.pathname &&
    global.window.location.pathname &&
    global.window.location.pathname !== '/' &&
    global.window.location.pathname.replace('/', '').replace('/', '');

  const pathnameValue = rawPathnameValue && decodeURI(rawPathnameValue);

  const [usernameValue, setUsernameValue] = useState(
    pathnameValue && pathnameValue !== 'undefined' ? pathnameValue : '',
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [result, setResult] = useState(null);
  const [scanDate, setScanDate] = useState(null);

  const getScanDate = () => {
    let scanTime = '';
    if (scanDate) {
      moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY') ===
      moment.utc(new Date().toISOString()).format('MMM DD, YYYY')
        ? (scanTime = moment.utc(new Date(scanDate).toISOString()).fromNow())
        : (scanTime = moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)'));
    } else {
      scanTime = 'a few seconds ago';
    }
    return scanTime;
  };

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      handleGo();
    }
  };

  const handleChange = e => {
    setErrorMessage('');
    setUsernameValue(e.target.value);
  };

  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const sendScanRequest = async (username, rescan) => {
    if (username && username.trim()) {
      setRequestLoading(true);
      setErrorMessage('');
      try {
        const res = await axios(`https://api.discoverprofile.com/discoverprofile`, {
          method: 'POST',
          data: { source: username, type: isValidEmail(username) ? 'email' : 'name', rescan: rescan },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (res && res.status === 200 && res.data && res.data.result) {
          setResult(res.data.result);
          rescan || !res.data.createdAt ? setScanDate(null) : setScanDate(res.data.createdAt);
          setRequestLoading(false);
        } else if (res && res.data && res.data.error) {
          setRequestLoading(false);
          res.data.error === 'Request limit reached.' ? setModalOpen(true) : setErrorMessage('Discovering failed.');
        }
      } catch (e) {
        setRequestLoading(false);
        setErrorMessage('Discovering failed.');
      }
    } else {
      setErrorMessage('Please enter valid nickname or username');
    }
  };

  const handleNewScanRequest = () => {
    const trimedValue = usernameValue.trim();
    const navLinkArr = trimedValue.includes('@') ? trimedValue.match(/^([^@]*)@/) : null;
    const navLink = navLinkArr ? navLinkArr[1] : trimedValue;

    setResult(null);
    setScanDate(null);
    if ((navLink && navLink.includes(' ')) || !navLink) {
      setErrorMessage('Please enter valid nickname or username');
      return;
    }
    navigate(`/${navLink.toLocaleLowerCase()}/`);
  };

  const handleGo = () => {
    if (pathnameValue === usernameValue) {
      sendScanRequest(pathnameValue, true);
    } else {
      handleNewScanRequest();
    }
  };

  useEffect(() => {
    if (pathnameValue && pathnameValue.includes('index.html')) {
      navigate(`/${pathnameValue.replace('index.html', '')}`);
      return;
    }
    if (pathnameValue && pathnameValue !== undefined) {
      setUsernameValue(pathnameValue);
      sendScanRequest(pathnameValue, false);
    }
  }, [pathnameValue]);

  return (
    <Layout
      title={
        pathnameValue && pathnameValue !== 'undefined'
          ? `${pathnameValue}'s Social Media Profiles`
          : `Discover Social Media Profiles | Social Media Profiles Finder`
      }
      metaName={'description'}
      metaContent={
        pathnameValue && pathnameValue !== 'undefined'
          ? ''
          : 'Discover social media profiles for free in minutes with Discover profile! Find new ways to connect including Facebook, Instagram, Twitter and Tik Tok'
      }
    >
      <h1 className="page-title">Find social media profiles in seconds</h1>
      <h2 className="page-subtitle mb-4">
        Discover Profile helps you connect with prospects on all their social media channels by discovering all their
        social media profiles in seconds.
      </h2>
      <div className="form-container field is-grouped">
        <input
          className="input is-rounded inputBar"
          type="text"
          placeholder="Nickname or username"
          value={usernameValue}
          onChange={handleChange}
          onKeyPress={handleCaptureOnEnter}
          maxLength={320}
        />

        <div className="buttons is-block btn_capture">
          <button
            className={`button is-primary is-outlined is-medium is-rounded ${requestLoading ? 'disabled' : ''}`}
            onClick={handleGo}
          >
            GO
            {arrowRight}
          </button>
        </div>
      </div>
      {requestLoading && (
        <div className="control" style={{ paddingTop: '10px' }}>
          <div className="is-flex" style={{ justifyContent: 'center', textAlign: 'center' }}>
            <progress className="progress is-small is-primary" max="100" style={{ maxWidth: '750px' }}>
              5%
            </progress>
          </div>
        </div>
      )}
      {result && (
        <div className="scan-info">
          <div className="py-1">
            Time scraped: <strong>{getScanDate()}</strong>
          </div>
          {pathnameValue && pathnameValue !== 'undefined' && (
            <div className="ml-md-auto buttons is-block secondary-btn py-1">
              <button
                className={`button is-primary is-outlined is-medium ${requestLoading ? 'disabled' : ''}`}
                onClick={() => sendScanRequest(pathnameValue, true)}
              >
                Scrape again
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="svg-icon"
                    d="M13.8064 6.27219C13.6639 6.25647 13.0783 6.19183 12.997 6.18283C12.6022 3.0042 9.89065 0.476273 6.52373 0.476273C2.9181 0.476273 0 3.39404 0 7C0 10.6057 2.91777 13.5237 6.52373 13.5237C8.41816 13.5237 10.1314 12.7163 11.3298 11.4114C11.543 11.1793 11.5276 10.8183 11.2955 10.6051C11.0634 10.3919 10.7023 10.4073 10.4892 10.6394C9.50504 11.711 8.09452 12.3824 6.52373 12.3824C3.55586 12.3824 1.14133 9.96786 1.14133 7C1.14133 4.03214 3.55586 1.6176 6.52373 1.6176C9.16861 1.6176 11.374 3.53524 11.8226 6.05319C11.6639 6.03567 11.8205 6.05295 10.9756 5.95968C10.8089 5.94128 10.6852 6.1107 10.7534 6.26388L11.9015 8.84163C11.9691 8.99339 12.1751 9.01614 12.2742 8.88278L13.9569 6.61751C14.0569 6.48293 13.9731 6.29059 13.8064 6.27219Z"
                    fill="#BBC2C5"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
      {errorMessage && (
        <div
          className="is-flex"
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: '20px',
          }}
        >
          <article className="message is-danger" style={{ maxWidth: '300px' }}>
            <div className="message-body">{errorMessage}</div>
          </article>
        </div>
      )}
      {result && <Result result={result} />}
      {result && <CTA />}
      {!result && <Steps />}
      {!result && <CTA />}
      <Modal
        isOpen={modalOpen}
        modalToggle={modalToggle}
        title="Login to continue"
        onButtonClick={() => {}}
        buttonText={'Unlock more searches'}
      >
        <p className="text-center">You have reached your search limit for the day.</p>
      </Modal>
    </Layout>
  );
};
