import React from 'react';
import successIcon from '../../img/icons/success.svg';
import './styles.scss';

export default ({ isOpen, modalToggle, title, onButtonClick, buttonText, children, className }) => {
  const handleClick = event => {
    return modalToggle();
  };
  return (
    <div className={`modal is-clipped ${isOpen ? 'is-active' : ''} ${className ? className : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <img src={successIcon} alt="icon" className="popup-icon" />
        {children || title}
        <div className="modal-content-footer">
          <button
            className="button m-auto"
            onClick={() => {
              onButtonClick();
              handleClick();
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={handleClick}></button>
    </div>
  );
};
