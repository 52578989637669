import aboutMe from '../img/socials/about-me.png';
import angelist from '../img/socials/angelist.svg';
import badoo from '../img/socials/badoo.svg';
import bandcamp from '../img/socials/bandcamp.svg';
import behance from '../img/socials/behance.svg';
import bitbucket from '../img/socials/bitbucket.svg';
import bitly from '../img/socials/bitly.svg';
import campsite from '../img/socials/campsite.png';
import canva from '../img/socials/canva.svg';
import codecademy from '../img/socials/codecademy-1.svg';
import contactinbio from '../img/socials/contactinbio.png';
import cybrary from '../img/socials/cybrary.png';
import dailymotion from '../img/socials/dailymotion.svg';
import disqus from '../img/socials/disqus.svg';
import dribble from '../img/socials/dribble.svg';
import ebay from '../img/socials/ebay.svg';
import facebook from '../img/socials/facebook.svg';
import fanpop from '../img/socials/fanpop.svg';
import fiverr from '../img/socials/fiverr.svg';
import flickr from '../img/socials/flickr.svg';
import flipboard from '../img/socials/flipboard.svg';
import fotolog from '../img/socials/fotolog.png';
import foursquare from '../img/socials/foursquare.svg';
import gamespot from '../img/socials/gamespot.png';
import github from '../img/socials/github.svg';
import googleplus from '../img/socials/googleplus.svg';
import gravatar from '../img/socials/gravatar.svg';
import houzz from '../img/socials/houzz.svg';
import hubpages from '../img/socials/hubpages.svg';
import imgur from '../img/socials/imgur.svg';
import instagram from '../img/socials/instagram.svg';
import instructables from '../img/socials/instructables.png';
import keybase from '../img/socials/keybase.svg';
import kongregate from '../img/socials/kongregate.png';
import linkedin from '../img/socials/linkedin.svg';
import linktree from '../img/socials/linktree.png';
import medium from '../img/socials/medium.svg';
import mixcloud from '../img/socials/mixcloud.png';
import myspace from '../img/socials/myspace.svg';
import patreon from '../img/socials/patreon.svg';
import paypal from '../img/socials/paypal.svg';
import photobucket from '../img/socials/photobucket.svg';
import pinterest from '../img/socials/pinterest.svg';
import quora from '../img/socials/quora.svg';
import reddit from '../img/socials/reddit.svg';
import roblox from '../img/socials/roblox.svg';
import shorBy from '../img/socials/shor-by.png';
import slideshare from '../img/socials/slideshare.svg';
import snapchat from '../img/socials/snapchat.svg';
import soundcloud from '../img/socials/soundcloud.svg';
import spotify from '../img/socials/spotify.svg';
import steam from '../img/socials/steam.svg';
import swipop from '../img/socials/swipop.png';
import tapBio from '../img/socials/tap-bio.png';
import telegram from '../img/socials/telegram.svg';
import tiktok from '../img/socials/tiktok.svg';
import trello from '../img/socials/trello.svg';
import tripadvisor from '../img/socials/tripadvisor.svg';
import tumblr from '../img/socials/tumblr.svg';
import twitch from '../img/socials/twitch.svg';
import twitter from '../img/socials/twitter.svg';
import vimeo from '../img/socials/vimeo.svg';
import vine from '../img/socials/vine.svg';
import vkontakte from '../img/socials/vkontakte.png';
import wattpad from '../img/socials/wattpad.svg';
import wikipedia from '../img/socials/wikipedia.svg';
import wordpress from '../img/socials/wordpress.svg';
import youtube from '../img/socials/youtube.svg';
import GaG from '../img/socials/9gag.svg';
import Academia from '../img/socials/academia.png';
import appleDiscussions from '../img/socials/apple-discussion.svg';
import askFedora from '../img/socials/askFedora.png';
import askFM from '../img/socials/askfm.svg';
import binarySearch from '../img/socials/binarySearch.png';
import bitcoinforum from '../img/socials/bitcoinforum.png';

import bookcrossing from '../img/socials/bookcrossing.png';
import buymeacoffee from '../img/socials/buymeacoffee.png';
import CapFriendly from '../img/socials/CapFriendly.png';
import Chess from '../img/socials/chess.svg';
import CloudflareCommunity from '../img/socials/CloudflareCommunity.png';
import Codecademy from '../img/socials/codecademy-2.svg';
import Codepen from '../img/socials/Codepen.svg';
import Codewars from '../img/socials/codewars.svg';
import Contently from '../img/socials/Contently.png';
import Coroflot from '../img/socials/Coroflot.png';
import Countable from '../img/socials/Countable.png';
import Cracked from '../img/socials/Cracked.png';
import Crevado from '../img/socials/Crevado.png';
import dev from '../img/socials/dev.svg';
import Designspiration from '../img/socials/Dspncdn.png';
import DeviantART from '../img/socials/Deviantart.png';
import Discogs from '../img/socials/Discogs.png';
import DiscussElastic from '../img/socials/DiscussElastic.png';
import DockerHub from '../img/socials/docker.svg';
import Dribbble from '../img/socials/dribbble.svg';
import Duolingo from '../img/socials/Duolingo.png';
import ello from '../img/socials/ello.svg';
import etsy from '../img/socials/etsy.png';
import euw from '../img/socials/euw.png';
import eyeem from '../img/socials/eyeem.png';
import f3 from '../img/socials/f3.png';
import fandom from '../img/socials/fandom.png';
import flightradar24 from '../img/socials/flightradar24.svg';
import quizlet from '../img/socials/quizlet.png';
import producthunt from '../img/socials/producthunt.svg';
import tinder from '../img/socials/tinder.svg';
import wix from '../img/socials/wix.svg';
import uid from '../img/socials/uid.png';
import pr0gramm from '../img/socials/pr0gramm.png';
import pikabu from '../img/socials/pikabu.png';
import opennet from '../img/socials/opennet.png';
import npm from '../img/socials/npm.png';
import livelib from '../img/socials/livelib.png';
import slack from '../img/socials/slack.svg';

export const socialChannels = {
  '9gag': GaG,
  contactinbio: contactinbio,
  'about.me': aboutMe,
  'academia.edu': Academia,
  angellist: angelist,
  'apple discussions': appleDiscussions,
  'ask fedora': askFedora,
  askfm: askFM,
  badoo: badoo,
  bandcamp: bandcamp,
  behance: behance,
  binarysearch: binarySearch,
  bitbucket: bitbucket,
  bitcoinforum: bitcoinforum,
  bitly: bitly,
  bookcrossing: bookcrossing,
  buymeacoffee: buymeacoffee,
  capfriendly: CapFriendly,
  chess: Chess,
  cloudflarecommunity: CloudflareCommunity,
  codecademy: Codecademy,
  codepen: Codepen,
  codewars: Codewars,
  contently: Contently,
  coroflot: Coroflot,
  countable: Countable,
  cracked: Cracked,
  crevado: Crevado,
  campsite: campsite,
  canva: canva,
  codeacademy: codecademy,
  cybrary: cybrary,
  dailymotion: dailymotion,
  'dev community': dev,
  designspiration: Designspiration,
  deviantART: DeviantART,
  discogs: Discogs,
  'discuss.elastic.co': DiscussElastic,
  'docker hub': DockerHub,
  dribbble: Dribbble,
  duolingo: Duolingo,
  disqus: disqus,
  dribble: dribble,
  ebay: ebay,
  ello: ello,
  etsy: etsy,
  euw: euw,
  eyeem: eyeem,
  'f3.cool': f3,
  fandom: fandom,
  flightradar24: flightradar24,
  facebook: facebook,
  fanpop: fanpop,
  fiverr: fiverr,
  flickr: flickr,
  flipboard: flipboard,
  fotolog: fotolog,
  foursquare: foursquare,
  gamespot: gamespot,
  'gitHub support community': github,
  googleplus: googleplus,
  gravatar: gravatar,
  houzz: houzz,
  hubpages: hubpages,
  imgur: imgur,
  instagram: instagram,
  instructables: instructables,
  keybase: keybase,
  kongregate: kongregate,
  linkedin: linkedin,
  linktree: linktree,
  medium: medium,
  mixcloud: mixcloud,
  myspace: myspace,
  patreon: patreon,
  paypal: paypal,
  photobucket: photobucket,
  pinterest: pinterest,
  quora: quora,
  reddit: reddit,
  roblox: roblox,
  'shor.by': shorBy,
  slideshare: slideshare,
  snapchat: snapchat,
  soundcloud: soundcloud,
  spotify: spotify,
  steam: steam,
  swipop: swipop,
  'tap.bio': tapBio,
  telegram: telegram,
  tiktok: tiktok,
  trello: trello,
  tripadvisor: tripadvisor,
  tumblr: tumblr,
  twitch: twitch,
  twitter: twitter,
  vimeo: vimeo,
  vine: vine,
  vkontakte: vkontakte,
  wattpad: wattpad,
  wikipedia: wikipedia,
  wordpress: wordpress,
  youtube: youtube,
  quizlet: quizlet,
  producthunt: producthunt,
  tinder: tinder,
  wix: wix,
  uid: uid,
  pr0gramm: pr0gramm,
  pikabu: pikabu,
  opennet: opennet,
  livelib: livelib,
  slack: slack,
  npm: npm,
};
