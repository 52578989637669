import React from 'react';

import banner from '../../img/layout/social-media-scraper.png';
import './styles.scss';

const CTA = () => {
  return (
    <div className="cta-container row mt-5">
      <div className="col-12 col-lg-6">
        <h5 className="cta-title">Discover & scrape social media profiles in seconds</h5>
        <p className="cta-p">
          Instantly collect public data from social networks like Instagram, TikTok, Facebook, including profiles,
          images, groups, jobs, URLs, and videos.
        </p>
        <div className="d-flex">
          <a
            className="button is-primary is-outlined is-medium is-rounded cta-btn"
            href="https://get.brightdata.com/web-scrapers"
            target='_blank'
            rel='noopener'
          >
            <span className="d-none d-md-block">Start free with Brightdata Scraper</span>
            <span className="d-md-none">Get started</span>
          </a>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <a href="https://get.brightdata.com/web-scrapers" target='_blank' rel='noopener'>
          <img
            src={banner}
            alt="Social Media Scraper"
            style={{ maxWidth: '450px', width: '100%', padding: '15px', borderRadius: '10px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default CTA;
