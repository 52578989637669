import React, { useEffect, useState, useRef } from 'react';
import { socialChannels } from '../../constants';
import { copyIcon } from '../../img/svg-icons';
import noIcon from '../../img/socials/no-image.svg';
import './styles.scss';

export default ({ result }) => {
  const inputElement = useRef(null);
  const [copied, setCopied] = useState(false);
  const [showNotFounds, setShowNotFounds] = useState(false);
  const [socials, setSocials] = useState(null);
  const [socialsFullList, setSocialsFullList] = useState(null);

  useEffect(() => {
    const data = [];
    const dataFull = [];
    if (result) {
      result.map(item => {
        if (item.isExist) {
          data.push({ icon: socialChannels[item.source.toLowerCase()] || noIcon, value: item.url, name: item.source });
          dataFull.push({
            icon: socialChannels[item.source.toLowerCase()] || noIcon,
            value: item.url,
            name: item.source,
          });
        } else {
          dataFull.push({ icon: socialChannels[item.source.toLowerCase()] || noIcon, value: '', name: item.source });
        }
      });
    }
    setSocials(data);
    setSocialsFullList(dataFull);
  }, [result]);

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 5000);
  }, [copied]);

  const handleInputClick = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const inputDom = inputElement.current;
    if (!inputDom) {
      return;
    }
    navigator.clipboard.writeText(value || '');
    inputDom.focus();
    inputDom.setSelectionRange(0, value.length);
    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <div className="results-container">
      <div className="socials-contaner">
        <h5>
          Social Links
          <label className="checkbox">
            <input type="checkbox" checked={showNotFounds} onChange={() => setShowNotFounds(!showNotFounds)} />
            Show not founds
          </label>
        </h5>
        <div className="values-container">
          {((socials && !showNotFounds) || (socialsFullList && showNotFounds)) &&
          (showNotFounds ? socialsFullList : socials).length > 0 ? (
            (showNotFounds ? socialsFullList : socials).map(item => (
              <div className="value-row">
                <img src={item.icon} alt="email" />
                <div className="w-full">
                  <div className="value-name">{item.name}</div>
                  {item.value ? (
                    <div className="d-flex">
                      <div className="value-text social text-truncate">
                        <a href={item.value} target="_blank" title={item.value} rel="noopener noreferrer">
                          {' '}
                          {item.value}
                        </a>
                      </div>
                      <input
                        ref={inputElement}
                        value={item.value}
                        style={{ visibility: 'hidden', height: 0, width: 0 }}
                      />
                      <div
                        className="copy"
                        onClick={e => {
                          handleInputClick(e, item.value);
                        }}
                        title="Click to copy"
                      >
                        {copyIcon}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <div className="value-text social">
                        <span>Not found</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center" style={{ background: 'white' }}>
              Nothing to show
            </div>
          )}
        </div>
      </div>
      {copied && (
        <div className="copied-text">
          <p>Copied!</p>
        </div>
      )}
    </div>
  );
};
