import React from 'react';
import { step1, step2, step3 } from '../../img/svg-icons';
import './styles.scss';

export default ({}) => {
  return (
    <div className="setps-container">
      <div className="steps-list">
        <div className="step">
          <span className="point">{step1}</span>
          <p>Type the nickname or username of your prospect</p>
        </div>
        <div className="step">
          <span className="point">{step2}</span>
          <p>
            Our free profile discovery tool will scour the web to find their contact details and social media profiles
          </p>
        </div>
        <div className="step">
          <span className="point">{step3}</span>
          <p>Find new ways to connect including Facebook, Instagram, Twitter and Tik Tok</p>
        </div>
      </div>
    </div>
  );
};
